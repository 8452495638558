import React, { useEffect } from 'react'

import './getDemoForm.css'

const getDemoForm = () => {
  useEffect(() => {
    const script = document.createElement('script')

    script.src = '//js.hsforms.net/forms/embed/v2.js'
    document.body.appendChild(script)

    script.addEventListener('load', () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          target: '#hsGetDemoForm',
          region: 'na1',
          portalId: '4284310',
          formId: 'a7229eb9-1aac-4a60-8020-521ac8e67119',
          version: 'V2_PRERELEASE'
        })
      }
    })
  }, [])

  return <div id="hsGetDemoForm" />
}

export default getDemoForm
