import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next'
import {
  Stepper,
  Step,
  StepLabel,
  stepLabelClasses,
  StepContent,
  StepConnector,
  stepConnectorClasses
} from '@mui/material'
import styled from '@emotion/styled'

import Layout from 'layouts/index'
import Seo from 'components/seo'
import HsGetDemoForm from 'components/hubspot/getDemoForm'
import CouponCard from 'components/card/coupon'
import Icon from 'components/icon'

import companyList from '/static/data/company.static'

const CustomStepContent = styled(StepContent)(() => ({
  borderColor: '#9CB1B4',
  borderLeftWidth: 3,
  borderStyle: 'dotted',
  left: 16,
  paddingBottom: 20,
  paddingLeft: 38,
  position: 'relative'
}))
const CustomStepperConnector = styled(StepConnector)(() => ({
  [`& .${stepConnectorClasses.line}`]: {
    display: 'none'
  }
}))
const CustomStepLabel = styled(StepLabel)(() => ({
  [`& .${stepLabelClasses.label}`]: {
    fontSize: 24,
    fontWeight: 400
  }
}))
const CustomStepIconRoot = styled('div')(() => ({
  alignItems: 'center',
  backgroundColor: '#fff',
  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
  borderRadius: '50%',
  color: '#3a3a3a',
  display: 'flex',
  fontSize: 32,
  height: 60,
  justifyContent: 'center',
  width: 60,
  zIndex: 1
}))
const CustomStepIcon = ({ icon }) => {
  const icons = {
    1: (
      <img
        alt="step1 icon"
        src="https://www.mlytics.com/wp-content/uploads/2023/04/step1_Diagnose.webp"
        height="32"
        width="32"
      />
    ),
    2: <Icon className="text-blue-200" fontSize="32px" name="emoji_objects_outlined" />,
    3: <Icon className="text-blue-200" fontSize="32px" name="local_activity_outlined" />
  }
  return <CustomStepIconRoot>{icons[String(icon)]}</CustomStepIconRoot>
}
const TextWithCheckedIcon = ({ text = '' }) => {
  return (
    <div className="flex items-stretch text-blue-200">
      <span style={{ flexBasis: 18 }}>
        <Icon className="leading-6 mr-2" fontSize="18px" name="done" />
      </span>
      <span>{text}</span>
    </div>
  )
}

const GetADemoPage = () => {
  const { t } = useTranslation()
  const stepperSectionRef = useRef(null)

  return (
    <Layout hasNav={false} hasFooter={false}>
      <React.Fragment>
        <Seo
          seo={{
            metaTitle: t('getDemo.meta.title'),
            metaDescription: t('getDemo.meta.description'),
            metaUrl: 'https://www.mlytics.com/lp/get-a-demo/',
            shareImage: {
              imageUrl: 'https://www.mlytics.com/wp-content/uploads/2023/03/og_v5.jpg',
              imageWidth: 1200,
              imageHeight: 630,
              imageType: 'image/jpeg'
            }
          }}
        />
        <section>
          <div className="h-full lg:w-1/2 relative">
            <div className="h-full px-4 lg:px-14 py-8">
              <div className="flex flex-col h-full">
                <Link to="/" className="inline-block my-2">
                  <img
                    alt="Mlytics"
                    src="https://www.mlytics.com/wp-content/uploads/2021/11/logo_Mlytics_regular.svg"
                    height="75"
                    width="150"
                  />
                </Link>
                <h1 className="mt-10 text-primary-500">
                  <Trans i18nKey="getDemo.chatBot.title">
                    <span className="text-secondary-600"></span>
                  </Trans>
                </h1>
              </div>
            </div>
          </div>
          <div className="bg-blue-300 flex justify-center lg:fixed inset-y-0 overflow-y-scroll right-0 w-full lg:w-1/2">
            <div className="px-6">
              <h1 className="text-3xl lg:text-4.5xl mb-6 text-center text-white" style={{ marginTop: '13vh' }}>
                {t('getDemo.title')}
              </h1>
              <div className="pb-10">
                <HsGetDemoForm />
                <div className="cursor-default mt-6 text-center text-white">
                  <Trans i18nKey="getDemo.signUpHint">
                    <Link to="/sign-up" className="font-bold"></Link>
                  </Trans>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section ref={stepperSectionRef} className="w-full lg:w-1/2">
          <div className="px-6 lg:px-16 py-10">
            <Stepper connector={<CustomStepperConnector />} orientation="vertical" nonLinear>
              <Step expanded>
                <CustomStepLabel StepIconComponent={CustomStepIcon}>
                  <h2 className="text-2xl lg:text-2.5xl text-blue-200">{t('getDemo.stepper.step1.headerText')}</h2>
                </CustomStepLabel>
                <CustomStepContent>
                  <div className="mb-2 text-primary-500">{t('getDemo.stepper.step1.contentText')}</div>
                  <TextWithCheckedIcon text={t('getDemo.stepper.step1.feature.text1')} />
                  <TextWithCheckedIcon text={t('getDemo.stepper.step1.feature.text2')} />
                </CustomStepContent>
              </Step>
              <Step expanded>
                <CustomStepLabel StepIconComponent={CustomStepIcon}>
                  <h2 className="text-2xl lg:text-2.5xl text-blue-200">{t('getDemo.stepper.step2.headerText')}</h2>
                </CustomStepLabel>
                <CustomStepContent>
                  <div className="mb-2 text-primary-500">{t('getDemo.stepper.step2.contentText')}</div>
                  <TextWithCheckedIcon text={t('getDemo.stepper.step2.feature.text1')} />
                </CustomStepContent>
              </Step>
              <Step expanded>
                <CustomStepLabel StepIconComponent={CustomStepIcon}>
                  <h2 className="text-2xl lg:text-2.5xl text-blue-200">{t('getDemo.stepper.step3.headerText')}</h2>
                </CustomStepLabel>
                <CustomStepContent style={{ border: 'transparent' }}>
                  <div className="mb-2 text-primary-500">
                    <div className="mb-3">
                      <Trans i18nKey="getDemo.stepper.step3.contentText">
                        <strong></strong>
                      </Trans>
                    </div>
                    <div className="-ml-8 lg:ml-0">
                      <CouponCard />
                    </div>
                  </div>
                </CustomStepContent>
              </Step>
            </Stepper>
          </div>
          <div className="pt-10 pb-20 px-6">
            <h2 className="text-center text-3xl lg:text-4.5xl text-primary-500">
              {t('videoStreamAnalytics.section11.subtitle')}
            </h2>
            <div className="text-blue-200 text-center text-lg lg:text-2xl mt-2">
              {t('getDemo.customerList.subtitle')}
            </div>
            <div className="flex flex-wrap justify-center pt-11">
              {companyList.map((item) => (
                <img
                  key={item.alt}
                  src={item.src}
                  alt={item.alt}
                  style={{ height: '76px', width: 'auto' }}
                  className="mx-4"
                />
              ))}
            </div>
          </div>
        </section>
      </React.Fragment>
    </Layout>
  )
}
CustomStepIcon.propTypes = {
  icon: PropTypes.number
}
TextWithCheckedIcon.propTypes = {
  text: PropTypes.string
}

export default GetADemoPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
